import React from 'react';

import SEO from '../components/SEO';
import Layout from '../components/Layout';

const Sobre = () => {
  return (
    <>
      <SEO title="404" />
      <Layout>
        <h1>Página 404</h1>
      </Layout>
    </>
  );
};

export default Sobre;
